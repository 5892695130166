import React from "react";
import PropTypes from "prop-types";
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import SimpleFormCTA from "./SimpleFormCTA";

import Mailchimp from './react-mailchimp-form/src/index.js'
 
const getAjaxUrl = url => url.replace("/post?", "/post-json?");

class MailchimpSubscribe extends React.Component {
  state = {
    status: null,
    message: null
  };
  subscribe = data => {
    const params = toQueryString(data);
    const url = getAjaxUrl(this.props.url) + "&" + params;
    this.setState(
      {
        status: "sending",
        message: null
      },
      () =>
        jsonp(
          url,
          {
            param: "c"
          },
          (err, data) => {
            if (err) {
              this.setState({
                status: "error",
                message: err
              });
            } else if (data.result !== "success") {
              this.setState({
                status: "error",
                message: data.msg
              });
            } else {
              this.setState({
                status: "success",
                message: data.msg
              });
            }
          }
        )
    );
  };
  render() {
    return this.props.render({
      subscribe: this.subscribe,
      status: this.state.status,
      message: this.state.message
    });
  }
}

MailchimpSubscribe.propTypes = {
  render: PropTypes.func,
  url: PropTypes.string.isRequired
};

MailchimpSubscribe.defaultProps = {
  render: ({ subscribe, status, message }) => (
    <SimpleFormCTA
      status={status}
      message={message}
      onSubmitted={formData => subscribe(formData)}
    />
  )
};

export default MailchimpSubscribe;

export class MailchimpFormSubscribe extends React.Component {
  render() {
    return (
        <Mailchimp
        action={this.props.url}
        fields={[
          {
            name: 'FNAME',
            placeholder: 'First Name',
            type: 'text',
            required: true
          },
          {
            name: 'LNAME',
            placeholder: 'Last Name',
            type: 'text',
            required: true
          }, {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          },
          {
            name: 'JOB_TITLE',
            placeholder: 'Job Title',
            type: 'text',
            required: true
          },
          {
            name: 'COMPANY',
            placeholder: 'Company',
            type: 'text',
            required: true
          },
          {
            name: 'DOMAIN',
            placeholder: 'Domain (example.com)',
            type: 'text',
            required: true
          },
          // {
          //   name: 'Data of Interest',
          //   placeholder: 'Information Sought',
          //   type: 'checkboxes',
          //   required: true,
          //   // class: "form-check-input"
          // },
        ]}
                    
        // Add a personalized class
        // buttonClassName='btn btn-submit'
        // Change predetermined language
        messages = {
          {
            sending: "Sending...",
            success: "Thank you for your request! Someone will get back to you shortly.",
            error: "An unexpected internal error has occurred.",
            empty: "You must provide an e-mail.",
            duplicate: "Too many attempts for this email address",
            button: "Submit"
          }
        }
        />
    );
  }
}



MailchimpFormSubscribe.propTypes = { 
  url: PropTypes.string.isRequired
};