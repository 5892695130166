import React, { Component } from "react"

import PropTypes from "prop-types"
// import MailchimpSubscribe from "./mailchimp-cta"
import { MailchimpFormSubscribe } from "./mailchimp-cta"

class SubscribeCTA extends Component {
  render() {
    const url =
      "https://datapolicytrust.us20.list-manage.com/subscribe/post?u=a959898c9523af82e2845802e&amp;id=3d5de989a8"
    return (
      <div className="container mr-auto ml-auto text-center2  ">
        {/* <form className="form-inline text-center"> */}
        {/* <h2>Default Form</h2> */}
        {/* <MailchimpSubscribe url={url} /> */}

        <div className="border border-primary backend px-5 py-5">
          <h5>{this.props.title}</h5>
          <br />
          <MailchimpFormSubscribe url={url} />
          {/* <h2>Custom Form</h2>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        /> */}
          {/* </form> */}{" "}
        </div>
      </div>
    )
  }
}

SubscribeCTA.propTypes = {
  title: PropTypes.string.isRequired,
}

export default SubscribeCTA
