import React, { useState } from "react"

const Form = () => {
  const [title, setTitle] = useState("")
  const [excerpt, setExcerpt] = useState("")
  const [author, setAuthor] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      let payload = {
        poem: title,
        excerpt: excerpt,
        author: author,
      }

      const response = await fetch("/api/save-poem/", {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: { "Content-Type": "application/json" },
        redirect: "follow", // manual, *folslow, error
        referrer: "client", // no-referrer, *client
        body: JSON.stringify(payload),
      })

      const answer = await response.json()

      if (answer.success) {
        alert(answer.message)
      } else {
        alert(answer.errors[0].msg)
      }
    } catch (err) {
      alert("Error connecting to backend:", err)
    }
  }

  const handleTitleChange = e => {
    setTitle(e.currentTarget.value)
  }

  const handleExcerptChange = e => {
    setExcerpt(e.currentTarget.value)
  }

  const handleAuthorChange = e => {
    setAuthor(e.currentTarget.value)
  }

  return (
    <div className="border border-primary backend px-5 py-5">
      <h5>Request Your Report</h5>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              id="first_name"
              placeholder="First Name"
              onChange={handleTitleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              id="last_name"
              placeholder="Last Name"
              onChange={handleTitleChange}
            />
          </div>
        </div>

        <br />
        <div className="form-group">
          {/* <label for="email">Email address</label> */}
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>

        <div className="form-group">
          {/* <label for="title">Job Title</label> */}
          <input
            type="title"
            className="form-control"
            id="email"
            aria-describedby="titleHelp"
            placeholder="Job Title"
          />
        </div>

        <div className="form-group">
          {/* <label for="company">Company Name</label> */}
          <input
            type="company"
            className="form-control"
            id="company"
            aria-describedby="companyHelp"
            placeholder="Company Name"
          />
        </div>

        <div className="form-group">
          {/* <label for="domain">Domain</label> */}
          <input
            type="domain"
            className="form-control"
            id="domain"
            aria-describedby="domainHelp"
            placeholder="Domain"
          />
        </div>
        {/* 
      <div className="form-check">
        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        <label className="form-check-label" for="exampleCheck1">Check me out</label>
      </div> */}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        {/*  
        <input placeholder='Work Email' id='email' onChange={handleAuthorChange} />
        <input placeholder='Job Title' id='job' onChange={handleAuthorChange} /> */}
        {/* <input placeholder='Company Name' id='company' onChange={handleAuthorChange} /> */}
        {/* <input placeholder='Domain' id='domain' onChange={handleAuthorChange} /> */}
      </form>
    </div>
  )
}

export default Form
