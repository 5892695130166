import React from "react"

// a basic form
const SimpleForm = ({ status, message, className, style, onSubmitted }) => {
  let input
  const submit = () =>
    input &&
    input.value.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: input.value,
    })

  return (
    <div className={className} style={style}>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="form-group mb-2">
        <input
          ref={node => (input = node)}
          type="email"
          placeholder="joe@gmail.com"
          className="form-control"
          style={{ display: "inline-block", width: "50%", marginRight: "10px" }}
        />
        <button onClick={submit} className="btn btn-primary ">
          Submit
        </button>
      </div>
    </div>
  )
}

export default SimpleForm
